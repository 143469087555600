import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ArrowDownIcon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import { Badge } from 'src/sites/kits/UI'
import SidebarNavButton from './SidebarNavButton'
import EarlyAccessButton from '../EarlyAccessButton'

// We store the current user's community menu open state in local storage
const lsCommunityMenuOpen = 'community-menu-open'

const SidebarNav = () => {
   const { me, isLoggedIn } = useMe()
   const isVIP = me?.badges?.some(badge => badge.name === 'VIP') ?? false
   const isTeam = me?.badges?.some(badge => badge.name === 'Team') ?? false

   const [communityMenuVisible, setCommunityMenuVisible] = useState(() => {
      return window.localStorage.getItem(lsCommunityMenuOpen) === 'true' || false
   })

   useEffect(() => {
      window.localStorage.setItem(lsCommunityMenuOpen, communityMenuVisible)
   }, [communityMenuVisible])

   return (
      <div className="hidden lg:flex fixed top-0 left-0 right-0 bottom-0 justify-center pointer-events-none">
         <div className="flex flex-start w-full h-full max-w-screen-2xl">
            <div className="relative lg:w-[28%] xl:w-[25%] h-full border-r border-solid border-neutral-200 pointer-events-auto overflow-auto ">
               <div className="p-12">
                  <div className="h-40 lg:pt-8 xl:pt-12">
                     <Link to="/">
                        <img
                           src="/assets/custom/gather/images/gather-logo-light.png"
                           className="lg:h-7 xl:h-8 mb-8"
                           alt="Gather"
                        />
                     </Link>
                     <div className="w-12 border-t-4 border-solid border-black" />
                  </div>
                  <div className="text-base">
                     An everyday notebook for collecting your thoughts, notes and inspiration.
                     Crafted. Native. Local-first.
                  </div>
                  <div className="text-2xs weight-medium uppercase tracking-wider opacity-70 pt-4">
                     For Mac, iPad and iPhone
                  </div>
               </div>
               <div className="pl-12">
                  <SidebarNavButton linkTo="/" name="Overview" firstChild exact />
                  <SidebarNavButton linkTo="/features" name="Features" />
                  <SidebarNavButton linkTo="/releases" name="Releases" />
                  <SidebarNavButton linkTo="/pricing" name="Pricing" />
                  <SidebarNavButton
                     name="Community"
                     onClick={() => setCommunityMenuVisible(!communityMenuVisible)}>
                     <div
                        className={`transition-transform duration-100 ${
                           communityMenuVisible ? 'mt-1' : '-rotate-90'
                        }`}>
                        <ArrowDownIcon width="14px" height="14px" />
                     </div>
                  </SidebarNavButton>

                  {communityMenuVisible && (
                     <>
                        <SidebarNavButton linkTo="/events" name="Events" nested />
                        <SidebarNavButton linkTo="/bugs-issues" name="Bugs & Issues" nested />
                        <SidebarNavButton
                           linkTo="/feature-requests"
                           name="Feature Requests"
                           nested
                        />
                        {(isVIP || isTeam) && (
                           <SidebarNavButton
                              linkTo="/partners-sponsors"
                              name="Partners & Sponsors"
                              nested
                           />
                        )}
                     </>
                  )}

                  {!isLoggedIn && (
                     <div className="my-12">
                        <EarlyAccessButton />
                     </div>
                  )}
               </div>
               {isLoggedIn && (
                  <Link
                     to="/-/profile"
                     className="sticky bottom-0 left-0 right-0 flex items-center h-12 mt-8 pl-12 bg-white gap-1 hover:opacity-80">
                     <Badge backgroundColor="black" textColor="white">
                        {me.fullName.charAt(0)}
                     </Badge>
                     <div className="text-base font-semibold px-2">{me.fullName}</div>
                  </Link>
               )}
            </div>
         </div>
      </div>
   )
}

export default SidebarNav
