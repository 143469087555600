// =========================================================================================@@
// Last Updated Date: Dec 4, 2024
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { TabMenu, TabMenuButton, View } from 'oio-react'
import { Helmet } from 'react-helmet'
import { Link, useRouteMatch } from 'react-router-dom'
import { adminManageContentTypesUrl, initiativeUrl } from 'config/constants/urls'
import { useMe, useMetadataFieldList, useOrganization } from 'src/core/graphql/hooks'
import InitiativeList from 'src/sites/kits/Initiative/components/List'
import DiscussionListItemRow from 'src/sites/kits/Object/components/ListItem/DiscussionRow'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import useUpvoteToggler from 'src/sites/kits/Object/utils/useUpvoteToggler'
import PeopleAsideBlock from 'src/sites/kits/People/components/AsideBlock'
import {
   EmptyContentBlock,
   LayoutAside,
   LayoutContainer,
   LayoutContainerInner,
   Title
} from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const FILTER_BY_RECENT = 'recent'
const FILTER_BY_POPULARITY = 'popularity'
const FILTER_BY_FOLLOWING = 'following'

const CollectionHome = () => {
   const match = useRouteMatch()
   const [initiativeListFilterMode, setInitiativeListFilterMode] = useState(FILTER_BY_RECENT)

   const { initiative: page } = useContext(InitiativeHierarchyContext)
   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const { toggleUpvote } = useUpvoteToggler()

   const dynamicPageType = organization.initiativeTypes.find(t => t.id === page.dynamicPageType.id)

   const discussionInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'discussion')
      .map(t => t.id)

   // Metadata Field List
   const { metadataFieldList } = useMetadataFieldList({
      sources: discussionInitiativeTypeIds.map(typeId => ({ id: typeId, type: 'type' }))
   })

   // TODO: DO NOT HARDCODE AT SOME POINT
   const upvoteCountMetadataFields = metadataFieldList.filter(field => field.key === 'upvotes')
   const upvoteCountMetadataFieldIds = upvoteCountMetadataFields.map(f => f.id)

   const showAsideColumn = organization.slug !== 'gather'
   const showUpvoteControl = upvoteCountMetadataFieldIds.length > 0

   const initiativeListFilter = {
      archived: 'exclude',
      drafts: 'include',
      sortBy: 'dateLastUpdated',
      parentInitiativeId: page.id,
      limit: 20
   }

   if (initiativeListFilterMode === FILTER_BY_POPULARITY) {
      initiativeListFilter.sortBy = 'popularity'
   } else if (initiativeListFilterMode === FILTER_BY_FOLLOWING) {
      initiativeListFilter.userRelationshipFilter = {
         userId: 'me',
         hasElementType: 'subscribed'
      }
   }

   return (
      <LayoutContainer>
         <LayoutContainerInner>
            <ObjectStatusBanner />
            <Helmet title={`${page.name} | ${organization.name}`} />
            <LayoutAside.Container>
               <LayoutAside.Content borderStyle={showAsideColumn ? undefined : 'none'}>
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     height="88px"
                     className="ui-titlebar"
                     borderBottom="1px solid var(--primaryLineColor)"
                     width="100%">
                     <Title size="md">{page.name}</Title>
                     <div className="flex items-center">
                        <ObjectCreateControl
                           buttonHeight="var(--baseComponentHeight-sm)"
                           initiativeTypes={[dynamicPageType]}
                           parentInitiativeId={page.id}
                        />
                        <ObjectMoreButton
                           buttonSize="var(--baseComponentHeight-sm)"
                           color="transparent"
                           editUrl={`${match.url}/-/edit`}
                           popoverAnchorOriginVertical="top"
                           showInitiativeName
                        />
                     </div>
                  </View>
                  <View paddingBottom="40px">
                     <View display="none">
                        <TabMenu
                           buttonPaddingHorizontal="0px"
                           buttonSpacing="20px"
                           buttonSize="xl"
                           buttonTextSize="2"
                           highlightColor="#222">
                           <TabMenuButton
                              isActive={initiativeListFilterMode === FILTER_BY_RECENT}
                              onClick={() => setInitiativeListFilterMode(FILTER_BY_RECENT)}
                              name="Most Recent"
                           />
                           <TabMenuButton
                              isActive={initiativeListFilterMode === FILTER_BY_POPULARITY}
                              onClick={() => setInitiativeListFilterMode(FILTER_BY_POPULARITY)}
                              name="Most Popular"
                           />
                           <TabMenuButton
                              isActive={initiativeListFilterMode === FILTER_BY_FOLLOWING}
                              onClick={() => setInitiativeListFilterMode(FILTER_BY_FOLLOWING)}
                              name="Following"
                           />
                        </TabMenu>
                     </View>
                     <div>
                        {!dynamicPageType.enabled && (
                           <div className="ui-grid-cell --full-span">
                              <EmptyContentBlock
                                 height="90px"
                                 message={(
                                    <View>
                                       {`${dynamicPageType.namePlural} have been disabled by the Admins of ${organization.name}.`}
                                       <br />
                                       <Link
                                          to={adminManageContentTypesUrl}
                                          style={{ color: 'inherit' }}>
                                          <u>Click here</u>
                                       </Link>
                                       &nbsp;to re-enable them.
                                    </View>
                                 )}
                              />
                           </div>
                        )}
                        {dynamicPageType.enabled && (
                           <InitiativeList
                              emptyContent={(
                                 <div className="ui-grid-cell --full-span">
                                    <EmptyContentBlock
                                       height="90px"
                                       message="There is currently no content to show"
                                    />
                                 </div>
                              )}
                              filter={initiativeListFilter}>
                              {initiativeList => initiativeList.items.map((initiative, index) => {
                                 const currentUserHasUpvoted = initiative.currentUserActionValues
                                    .some(av => upvoteCountMetadataFieldIds
                                    .includes(av.metadataFieldId))

                                 const metadataField = upvoteCountMetadataFields
                                    .find(f => f.sourceId === initiative.type.id)

                                 return (
                                    <div key={initiative.id}>
                                       <DiscussionListItemRow
                                          borderStyle={
                                             index === 0
                                                ? ''
                                                : '1px solid var(--primaryLineColor)'
                                          }
                                          coverMedia={initiative.coverMedia}
                                          coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                                          iconName="discussion"
                                          linkTo={initiativeUrl(initiative)}
                                          meta={[initiative.dateAdded]}
                                          name={initiative.name}
                                          numThreadEntries={initiative.thread.numEntries}
                                          numViews={initiative.numViews}
                                          privacy={initiative.privacy}
                                          publishDate={initiative.publishDate}
                                          subtitle={initiative.subtitle}
                                          summary={initiative.body.summary}
                                          surveyEnabled={initiative.appsSurveyEnabled}
                                          // Discussion props
                                          addedBy={initiative.addedBy.fullName}
                                          archived={initiative.archived}
                                          authorBadges={initiative.addedBy.badges}
                                          currentUserCanUpvote={isLoggedIn}
                                          currentUserHasUpvoted={currentUserHasUpvoted}
                                          dateLastUpdated={initiative.dateLastUpdated}
                                          discussionFormat={organization.slug === 'gather' ? 'task' : initiative.discussionFormat}
                                          discussionIsClosed={
                                             initiative.discussionStatus?.closed
                                          }
                                          hasUnreadEntries={initiative.thread.hasUnreadEntries}
                                          numUpvotes={initiative.numUpvotes}
                                          onUpvoteToggle={() => {
                                             toggleUpvote({
                                                currentUserHasUpvoted,
                                                initiativeId: initiative.id,
                                                metadataFieldId: metadataField.id
                                             })
                                          }}
                                          pinned={initiative.pinned}
                                          showUpvoteControl={showUpvoteControl}
                                          typeSlug={initiative.type.slug}
                                       />
                                    </div>
                                 )
                              })}
                           </InitiativeList>
                        )}
                     </div>
                  </View>
               </LayoutAside.Content>
               {showAsideColumn && (
                  <LayoutAside.Aside>
                     <PeopleAsideBlock />
                  </LayoutAside.Aside>
               )}
            </LayoutAside.Container>
         </LayoutContainerInner>
      </LayoutContainer>
   )
}

export default CollectionHome
