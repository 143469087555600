import React, { useCallback, useRef } from 'react'
import fetch from 'cross-fetch'
import AppleSignin from 'react-apple-signin-auth'
import { useHistory } from 'react-router-dom'
import { AppleGlyph24Icon } from 'assets/icons'
import { useMe } from 'src/core/graphql/hooks'
import GatherLayout from 'src/sites/custom/gather/components/Layout'

const LoginApple = () => {
   const { me, refetch: refetchMe } = useMe()
   const nonce = useRef(window._launchGlobalNonce ?? `${Date.now()}`)
   const history = useHistory()

   const handleLogin = useCallback((result) => {
      fetch('/authorize', {
         method: 'post',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            user: result.user,
            id_token: result.authorization.id_token,
            nonce: nonce.current
         })
      }).then((response) => {
         if (response.status === 200) {
            refetchMe().then((res) => {
               const email = res?.data?.me?.email
               if (email) {
                  fetch('/gather/earlyaccess', {
                     method: 'POST',
                     headers: { 'Content-Type': 'application/json' },
                     body: JSON.stringify({ email })
                  }).finally(() => {
                     history.push('/-/profile/me')
                  })
               } else {
                  history.push('/-/profile/me')
               }
            })
         } else {
            window.alert('There was an error with authentication')
            // console.log('error', response)
         }
      })
   }, [])

   const handleError = (error) => {
      window.alert(`There was an error authenticating: ${error.message}`)
      // console.error(error)
   }

   return (
      <GatherLayout>
         <div className="flex flex-col items-center justify-center xl:h-screen pb-16">
            <div className="flex flex-col items-center max-w-[600px] py-24 px-12">
               <div className="w-full text-base text-center pb-8">
                  {/* eslint-disable-next-line max-len */}
                  To login to the Gather Community, create an account or request early access to Gather, please
                  continue with Apple.
               </div>
               <AppleSignin
                  // Auth options passed to AppleID.auth.init()
                  authOptions={{
                     clientId: process.env.NODE_ENV === 'production'
                        ? 'mother.communities.auth'
                        : 'mother.communities.auth.dev',
                     scope: 'email name',
                     redirectURI: process.env.NODE_ENV === 'production'
                        ? 'https://gather.do/authorize'
                        : 'https://motherco.ngrok.io/authorize',
                     nonce: nonce.current,
                     usePopup: true
                  }}
                  uiType="dark"
                  className="apple-auth-btn"
                  noDefaultStyle={false}
                  /** Allows to change the button's children, eg: for changing the button text */
                  buttonExtraChildren="Continue with Apple"
                  /** Extra controlling props */
                  onSuccess={handleLogin}
                  onError={handleError}
                  skipScript={false}
                  // Apple image props
                  // iconProps={{ style: { marginTop: '10px' } }} // default = undefined
                  render={props => (
                     <button
                        type="button"
                        className="flex items-center justify-center gap-2 bg-black text-white text-base font-semibold px-6 h-12 rounded-md cursor-pointer
                        hover:bg-gray-800"
                        {...props}>
                        <AppleGlyph24Icon width="14px" height="14px" color="#fff" />
                        Continue with Apple
                     </button>
                  )}
               />
            </div>
         </div>
      </GatherLayout>
   )
}

LoginApple.propTypes = {}

export default LoginApple
